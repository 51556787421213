export function transformCartData(cartItems) {
  const formatedCartItems = [];

  for (const id in cartItems) {
    const item = {
      id: cartItems[id].product_id,
      item_name: cartItems[id].name,
      currency: cartItems[id].currency,
      price: cartItems[id].price / 100,
      quantity: cartItems[id].quantity,
    };

    formatedCartItems.push(item);
  }
  return formatedCartItems;
}

export function addItemToCartEventFB(item, brand) {
  if (brand) {
    // Try catch needed to handle when cookies are not accepted
    try {
      typeof window !== 'undefined' &&
        window.fbq('track', 'AddToCart', {
          currency: item.currency,
          value: item.price / 100,
          contents: [
            {
              id: item.product_id,
              item_name: item.name,
              currency: item.currency,
              item_brand: brand.Brand,
              value: item.price / 100,
              quantity: 1,
            },
          ],
        });
    } catch {}
  } else {
    // Try catch needed to handle when cookies are not accepted
    try {
      typeof window !== 'undefined' &&
        window.fbq('track', 'AddToCart', {
          currency: item.currency,
          value: item.price / 100,
          contents: [
            {
              id: item.product_id,
              item_name: item.name,
              currency: item.currency,
              value: item.price / 100,
              quantity: 1,
            },
          ],
        });
    } catch {}
  }
}

export function purchaseEventFB(sessionData, lineItemsData) {
  // Try catch needed to handle when cookies are not accepted
  try {
    const formatedLineItems = [];

    for (const id in lineItemsData) {
      const item = {
        id: lineItemsData[id].price.product,
        item_name: lineItemsData[id].description,
        currency: lineItemsData[id].currency,
        price: lineItemsData[id].amount_total / 100,
        quantity: lineItemsData[id].quantity,
      };
      formatedLineItems.push(item);
    }
    typeof window !== 'undefined' &&
      window.fbq('track', 'Purchase', {
        currency: sessionData.currency,
        // transaction_id: sessionData.id,
        value: sessionData.amount_total / 100,
        contents: formatedLineItems,
      });
  } catch (e) {
    console.log(e);
  }
}

export function begingCheckoutEventFB(cartItems, totalPrice) {
  const checkoutItems = transformCartData(cartItems);
  // Try catch needed to handle when cookies are not accepted
  try {
    typeof window !== 'undefined' &&
      window.fbq('track', 'InitiateCheckout', {
        currency: checkoutItems[0].currency,
        value: totalPrice / 100,
        contents: checkoutItems,
      });
  } catch {}
}
