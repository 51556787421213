import React from 'react';
// import { AppBar, Toolbar, Typography, IconButton } from '@mui/material/';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
// import { Link } from 'gatsby';

const Footer = () => {
  return (
    <footer>
      <Box
        bgcolor={'#202026'}
        color={'white'}
        px={{ xs: 3, sm: 10 }}
        py={{ xs: 5, sm: 10 }}
      >
        <Container maxWidth="lg">
          <Grid container spacing={5}>
            <Grid item xs={12} sm={4}>
              <Box fontWeight={1000}>About us </Box>
              <Box>
                <Link
                  href="https://customsurgical.co/press/"
                  target="_blank"
                  rel="noreferrer"
                  color="inherit"
                  underline="none"
                  sx={{
                    ':hover, :active, :focus': {
                      color: '#FFA100',
                    },
                  }}
                >
                  Press
                </Link>
              </Box>
              <Box>
                <Link
                  href="https://customsurgical.co/careers/"
                  target="_blank"
                  rel="noreferrer"
                  color="inherit"
                  underline="none"
                  sx={{
                    ':hover, :active, :focus': {
                      color: '#FFA100',
                    },
                  }}
                >
                  Carers
                </Link>
              </Box>
              <Box>
                <Link
                  href="https://customsurgical.co/referral-program/"
                  target="_blank"
                  rel="noreferrer"
                  color="inherit"
                  underline="none"
                  sx={{
                    ':hover, :active, :focus': {
                      color: '#FFA100',
                    },
                  }}
                >
                  Referral Program
                </Link>
              </Box>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Box fontWeight={1000}>Support </Box>
              <Box>
                <Link
                  href="https://customsurgical.co/faqs/"
                  target="_blank"
                  rel="noreferrer"
                  color="inherit"
                  underline="none"
                  sx={{
                    ':hover, :active, :focus': {
                      color: '#FFA100',
                    },
                  }}
                >
                  FAQs
                </Link>
              </Box>
              <Box>
                <Link
                  href="https://customsurgical.co/manuals/"
                  target="_blank"
                  rel="noreferrer"
                  color="inherit"
                  underline="none"
                  sx={{
                    ':hover, :active, :focus': {
                      color: '#FFA100',
                    },
                  }}
                >
                  Manuals
                </Link>
              </Box>
              <Box>
                <Link
                  href="https://customsurgical.co/contact/"
                  target="_blank"
                  rel="noreferrer"
                  color="inherit"
                  underline="none"
                  sx={{
                    ':hover, :active, :focus': {
                      color: '#FFA100',
                    },
                  }}
                >
                  Contact us
                </Link>
              </Box>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Box fontWeight={1000}>Legal </Box>
              <Box>
                <Link
                  href="https://customsurgical.co/terms-conditions/"
                  target="_blank"
                  rel="noreferrer"
                  color="inherit"
                  underline="none"
                  sx={{
                    ':hover, :active, :focus': {
                      color: '#FFA100',
                    },
                  }}
                >
                  Terms
                </Link>
              </Box>
              <Box>
                <Link
                  href="https://customsurgical.co/privacy-policy/"
                  target="_blank"
                  rel="noreferrer"
                  color="inherit"
                  underline="none"
                  sx={{
                    ':hover, :active, :focus': {
                      color: '#FFA100',
                    },
                  }}
                >
                  Privacy Policy
                </Link>
              </Box>
              <Box>
                <Link
                  href="https://customsurgical.co/warranty/"
                  target="_blank"
                  rel="noreferrer"
                  color="inherit"
                  underline="none"
                  sx={{
                    ':hover, :active, :focus': {
                      color: '#FFA100',
                    },
                  }}
                >
                  Warranty
                </Link>
              </Box>
              <Box>
                <Link
                  href="https://customsurgical.co/shipping/"
                  target="_blank"
                  rel="noreferrer"
                  color="inherit"
                  underline="none"
                  sx={{
                    ':hover, :active, :focus': {
                      color: '#FFA100',
                    },
                  }}
                >
                  Shipping
                </Link>
              </Box>
            </Grid>
          </Grid>
          <Box pt={{ xs: 5, sm: 10 }}></Box>
          <Box borderTop={1} textAlign={'center'}>
            {' '}
            Custom Surgical
          </Box>
        </Container>
      </Box>
    </footer>
  );
};

export default Footer;
