export function transformCartData(cartItems) {
  const formatedCartItems = [];

  for (const id in cartItems) {
    const item = {
      item_id: cartItems[id].product_id,
      item_name: cartItems[id].name,
      currency: cartItems[id].currency,
      price: cartItems[id].price / 100,
      quantity: cartItems[id].quantity,
    };

    formatedCartItems.push(item);
  }
  return formatedCartItems;
}

export function addItemToCartEventGA(item, brand) {
  if (brand) {
    // Try catch needed to handle when cookies are not accepted
    try {
      typeof window !== 'undefined' &&
        window.gtag('event', 'add_to_cart', {
          currency: item.currency,
          value: item.price / 100,
          items: [
            {
              item_id: item.product_id,
              item_name: item.name,
              currency: item.currency,
              item_brand: brand.Brand,
              price: item.price / 100,
              quantity: 1,
            },
          ],
        });
    } catch {}
  } else {
    // Try catch needed to handle when cookies are not accepted
    try {
      typeof window !== 'undefined' &&
        window.gtag('event', 'add_to_cart', {
          currency: item.currency,
          value: item.price / 100,
          items: [
            {
              item_id: item.product_id,
              item_name: item.name,
              currency: item.currency,
              price: item.price / 100,
              quantity: 1,
            },
          ],
        });
    } catch {}
  }
}

export function purchaseEventGA(sessionData, lineItemsData) {
  // Try catch needed to handle when cookies are not accepted
  try {
    const formatedLineItems = [];

    for (const id in lineItemsData) {
      const item = {
        item_id: lineItemsData[id].price.product,
        item_name: lineItemsData[id].description,
        currency: lineItemsData[id].currency,
        price: lineItemsData[id].amount_total / 100,
        quantity: lineItemsData[id].quantity,
      };
      formatedLineItems.push(item);
    }
    typeof window !== 'undefined' &&
      window.gtag('event', 'purchase', {
        currency: sessionData.currency,
        transaction_id: sessionData.id,
        value: sessionData.amount_total / 100,
        items: formatedLineItems,
      });
  } catch (e) {
    console.log(e);
  }
}

export function removeItemFromCartEventGA(item) {
  // console.log(item);
  // Try catch needed to handle when cookies are not accepted
  try {
    typeof window !== 'undefined' &&
      window.gtag('event', 'remove_from_cart', {
        currency: item.currency,
        value: item.price / 100,
        items: [
          {
            item_id: item.product_id,
            item_name: item.name,
            currency: item.currency,
            price: item.price / 100,
            quantity: item.quantity,
          },
        ],
      });
  } catch {}
}

export function viewCartEventGA(cartItems, totalPrice) {
  const viewItems = transformCartData(cartItems);
  // Try catch needed to handle when cookies are not accepted
  try {
    typeof window !== 'undefined' &&
      window.gtag('event', 'view_cart', {
        currency: viewItems[0].currency,
        value: totalPrice / 100,
        items: viewItems,
      });
  } catch {}
}

export function removeAllItemsFromCartEventGA(cartItems, totalPrice) {
  const removeItems = transformCartData(cartItems);
  // Try catch needed to handle when cookies are not accepted
  try {
    typeof window !== 'undefined' &&
      window.gtag('event', 'remove_from_cart', {
        currency: removeItems[0].currency,
        value: totalPrice / 100,
        items: removeItems,
      });
  } catch {}
}

export function begingCheckoutEventGA(cartItems, totalPrice) {
  const checkoutItems = transformCartData(cartItems);
  // Try catch needed to handle when cookies are not accepted
  try {
    typeof window !== 'undefined' &&
      window.gtag('event', 'begin_checkout', {
        currency: checkoutItems[0].currency,
        value: totalPrice / 100,
        items: checkoutItems,
      });
  } catch {}
}
