import React from 'react';
import { Link } from 'gatsby';
import LogoCS from '../assets/images/logo_h_color_white.svg';

export default function LogoSvg({ ...props }) {
  return (
    <a path="/" {...props}>
      <LogoCS width="150px" height="auto" />
    </a>
  );
}
// const styles = {
//   logo: {
//     alignItems: 'center',
//     cursor: 'pointer',
//     display: 'inline-flex',
//     svg: {
//       height: 'auto',
//       width: [54, null, null, '30%', null],
//       ml: '5%',
//     },
//   },
// };
