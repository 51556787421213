import Menu from 'react-burger-menu/lib/menus/slide';
import React from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Link,
  Grid,
  IconButton,
  Box,
} from '@mui/material/';
import LogoSvg from '../logo';
// import { Link } from 'gatsby';
import CartButton from '../cart-button';

export default function Humburger() {
  const showSettings = (event) => {
    event.preventDefault();
  };

  const CustomSurgicalLogo = (
    <Link href="https://customsurgical.co/" target="_blank" rel="noopener">
      <Typography
        variant="h6"
        component="h1"
        style={{ display: 'flex', alignItems: 'center' }}
      >
        <LogoSvg />
      </Typography>
    </Link>
  );

  // NOTE: You also need to provide styles, see https://github.com/negomi/react-burger-menu#styling
  const displayMobile = () => {
    return (
      <Toolbar sx={{ justifyContent: 'space-between' }}>
        {/* <Box sx={{ flexGrow: 0 }}> */}
        <IconButton>{CustomSurgicalLogo}</IconButton>
        {/* </Box> */}

        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {/* <IconButton edge="end"> */}
          <CartButton />
          {/* </IconButton> */}
          {/* <IconButton edge="end"> */}
          <Menu right styles={styles}>
            <li>
              <Typography>
                <Link
                  marginBottom={2}
                  sx={styles.nav_link}
                  href="https://customsurgical.co/about/"
                  target="_blank"
                  rel="noopener"
                >
                  About
                </Link>
              </Typography>
            </li>
            <li>
              <Typography>
                <Link
                  marginBottom={2}
                  sx={styles.nav_link}
                  href="https://customsurgical.co/app/"
                  target="_blank"
                  rel="noopener"
                >
                  App
                </Link>
              </Typography>
            </li>
            <li>
              <Typography>
                <Link
                  marginBottom={2}
                  sx={styles.nav_link}
                  href="https://customsurgical.co/distributors/"
                  target="_blank"
                  rel="noopener"
                >
                  Distributors
                </Link>
              </Typography>
            </li>
            <li style={{ marginBottom: '1em' }}>
              <Typography>
                <Link
                  marginBottom={2}
                  sx={styles.nav_link}
                  href="https://blog.customsurgical.co/"
                  target="_blank"
                  rel="noopener"
                >
                  Blog
                </Link>
              </Typography>
            </li>
          </Menu>
          {/* </IconButton> */}
        </Box>
      </Toolbar>
    );
  };
  return (
    <Box sx={{ flexGrow: 1 }}>
      {' '}
      <AppBar sx={styles.headerAppBar}>{displayMobile()}</AppBar>
    </Box>
  );
}

const styles = {
  headerAppBar: {
    background: '#0A0A0A',
    paddingRight: '32px',
    paddingLeft: '32px',
  },
  bmBurgerButton: {
    position: 'relative',
    width: '24px',
    height: '24px',
    // right: '36px',
    marginLeft: '50%',
    // left: '36px',
    // top: '36px',
  },
  bmBurgerBars: {
    background: '#fff',
  },
  bmBurgerBarsHover: {
    background: 'rgb(255, 161, 0)',
  },
  bmCrossButton: {
    height: '24px',
    width: '24px',
  },
  bmCross: {
    background: '#bdc3c7',
  },
  bmMenuWrap: {
    position: 'fixed',
    height: '100%',
    top: '0px',
  },
  bmMenu: {
    background: '#373a47',
    padding: '2.5em 1.5em 0',
    fontSize: '1.15em',
  },
  bmMorphShape: {
    fill: '#373a47',
  },
  bmItemList: {
    color: '#b8b7ad',
    padding: '0.8em',
    // display: 'flex',
    // flexDirection: 'column',
  },
  bmItem: {
    // display: 'inline-block',
    margin: '0.8em',
  },
  bmOverlay: {
    background: 'rgba(0, 0, 0, 0.3)',
  },
  nav_link: {
    color: '#fff',
    fontWeight: '500',
    textDecoration: 'none',
    ':hover': {
      color: 'rgb(255, 161, 0)',
      transition: '0.3s all',
      textDecoration: 'none',
    },
  },
};
