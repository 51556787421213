import React from 'react';
import { Badge, IconButton } from '@mui/material';
import { useShoppingCart } from 'use-shopping-cart/react';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import { viewCartEventGA } from '../utils/ga-events';

const CartButton = () => {
  const { cartCount, handleCartClick, cartDetails, totalPrice } =
    useShoppingCart();

  const handleClick = () => {
    handleCartClick();
    viewCartEventGA(cartDetails, totalPrice);
  };

  return (
    <IconButton aria-label="number of items on the Cart" onClick={handleClick}>
      <Badge
        badgeContent={cartCount}
        color="secondary"
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <ShoppingCartOutlinedIcon
          sx={{ color: 'white' }}
          // fontSize={{ xs: 'medium', sm: 'large' }} // onClick={handleCartClick}
          fontSize="large"
          aria-label="Open cart"
        />
      </Badge>
    </IconButton>
  );
};

export default CartButton;
