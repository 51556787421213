import React, { useEffect, useState, useContext } from 'react';
import { Helmet } from 'react-helmet';
import '@stripe/stripe-js'; // https://github.com/stripe/stripe-js#import-as-a-side-effect

import { myContext } from '../utils/provider';

import CookieConsent, { Cookies } from 'react-cookie-consent';
import { useLocation } from '@reach/router'; // this helps tracking the location
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies';

import { Button } from 'gatsby-theme-material-ui';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { useStaticQuery, graphql } from 'gatsby';

import Header from './header/header';
import Humburger from './header/humburger';
import Footer from './footer/footer';
// import blockedCountries from '../data/blocked_countries.json';
// import { Box } from '@mui/system';

export default function Layout({ children, ...props }) {
  const data = useStaticQuery(graphql`
    query {
      worldImage: file(
        sourceInstanceName: { eq: "images" }
        name: { eq: "distributor-icon-page" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 100)
        }
        name
      }
      blockedCountries: allBlockedCountriesJson {
        nodes {
          country
          countryCode
        }
      }
    }
  `);

  const blockedCountries = data.blockedCountries.nodes;

  const location = useLocation();
  const context = useContext(myContext);

  const [isBlocked, setIsBlocked] = useState(null);

  const setBlockCountry = (countryCode) => {
    // if (!countryCode) {
    setIsBlocked(
      blockedCountries.some((item) => item.countryCode == countryCode)
    );
    // } else {
    //   setIsBlocked(false);
    // }
  };

  useEffect(() => {
    setBlockCountry(context.country);
  });

  return (
    <React.Fragment>
      <Helmet>
        <title>{`${props.helmetName} - Custom Surgical`}</title>
        {/* <script src="/src/utils/hotjar.js" type="text/javascript" /> */}
        {/* <script src="https://gso.amocrm.com/js/button.js?1649859383" /> */}
        <script
          dangerouslySetInnerHTML={{
            __html: `
    !function(a,m,o,c,r,m){a[m]={id:"136745",hash:"f1332997efdeeab3ff8ec49f50d244d634baaf1cd861ba1229021ca1dc39edfc",locale:"en",inline:false,setMeta:function(p){this.params=(this.params||[]).concat([p])}};a[o]=a[o]||function(){(a[o].q=a[o].q||[]).push(arguments)};var d=a.document,s=d.createElement('script');s.async=true;s.id=m+'_script';s.src='https://gso.amocrm.com/js/button.js?1649859383';d.head&&d.head.appendChild(s)}(window,0,'amoSocialButton',0,0,'amo_social_button')
  `,
          }}
        />
      </Helmet>
      <Box display={{ xs: 'none', sm: 'block' }}>
        <Header />
      </Box>
      <Box display={{ xs: 'flex', sm: 'none' }}>
        <Humburger />
      </Box>
      {/* <Header /> */}
      {!context.country ? (
        <div></div>
      ) : !isBlocked ? (
        <div>
          <div>{children}</div>
        </div>
      ) : (
        <div style={{ marginTop: 100 }}>
          <Box
            sx={{ display: 'flex' }}
            justifyContent={'center'}
            // maxWidth={'lg'}
            // display={'flex'}
            // justifyContent={'center'}

            // flexDirection={'column'}
            // alignItems={'center'}
          >
            <Grid
              container
              // display={'flex'}
              // flexDirection={'column'}
              // alignItems={'center'}
              rowSpacing={3}
              justifyContent={'center'}
              xs={10}
              sm={6}
              marginTop={3}
              marginBottom={3}
              padding={6}
              sx={{
                borderRadius: '5px',
                boxShadow: ' 0px 4px 9px rgba(0, 0, 0, 0.15)',
                backgroundColor: '#F9F9F9',
                paddingTop: 5,
                paddingBottom: 5,
                '& .MuiTextField-root': { m: 1, width: '25ch' },
              }}
            >
              <Grid item>
                <GatsbyImage
                  image={getImage(data.worldImage.childImageSharp)}
                  key={data.worldImage.name}
                  fadeIn={false}
                  loading="eager"
                />
              </Grid>
              <Grid item>
                <Typography variant="h4" sx={{ textAlign: 'justify' }}>
                  {/* <span style={{ fontWeight: 'bold', color: '#FFA100' }}>
                    Oops!{' '}
                  </span>{' '} */}
                  Looks like the E-commerce is not available in your country{' '}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h5" sx={{ textAlign: 'justify' }}>
                  Your country belongs to a country where we have a distributor.
                </Typography>
                <Typography variant="h5" sx={{ textAlign: 'justify' }}>
                  Please go to this page to check their contact details.
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h4">
                  <a
                    href="https://customsurgical.co/distributors/"
                    target="_blank"
                    rel="noreferrer"
                    style={{ textDecoration: 'none' }}
                  >
                    <Button variant="contained" color="primary">
                      Check distributor!
                    </Button>
                  </a>
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </div>
      )}
      {/* <Container maxWidth='lg' id='layout_div'>
      <Grid item xs={12} id='child_layout'>
      {children}
      </Grid>
    </Container> */}
      {/* <CookieConsent /> */}
      <Footer />
      <CookieConsent
        // debug={true}
        contentStyle={{ margin: 30 }}
        location="bottom"
        buttonText="Accept"
        enableDeclineButton
        declineButtonText="Decline"
        cookieName="gatsby-gdpr-cookies"
        // buttonClasses="MuiButton-containedPrimary "
        buttonStyle={styles.buttonStyle}
        declineButtonStyle={styles.declineButtonStyle}
        onAccept={() => {
          Cookies.set('gatsby-gdpr-cookies', 'true');
          // Cookies.set('gatsby-gdpr-google-analytics', 'true');
          // Cookies.set('gatsby-gdpr-facebook-pixel', 'true');
          // Cookies.set('gatsby-gdpr-hotjar', 'true');
          initializeAndTrack(location);
        }}
      >
        We use cookies on our website to give you the most relevant experience
        by remembering your preferences and repeat visits. By clicking “Accept",
        you consent to the use of cookies.
        <a
          href="https://customsurgical.co/privacy-policy/"
          target="_blank"
          rel="noreferrer"
          style={{ textDecoration: 'none', color: '#FFA100' }}
        >
          {' '}
          Learn More{' '}
        </a>
      </CookieConsent>
    </React.Fragment>
  );
}

const styles = {
  buttonStyle: {
    background: '#FFA100',
    border: '0',
    borderRadius: '4px',
    boxShadow:
      '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
    color: 'black',
    cursor: 'pointer',
    flex: '0 0 auto',
    padding: '6px 16px',
    margin: '15px',
    fontWeight: '500',
  },
  declineButtonStyle: {
    background: '#rgb(222, 223, 224)',
    border: '0',
    borderRadius: '4px',
    boxShadow:
      '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
    color: 'black',
    cursor: 'pointer',
    flex: '0 0 auto',
    padding: '6px 16px',
    margin: '15px',
    fontWeight: '500',
  },
};
