import React from 'react';
import { AppBar, Toolbar, Typography, Link } from '@mui/material/';

// import { Link } from 'gatsby';
import LogoSvg from '../logo';
import CartButton from '../cart-button';

export default function Header() {
  const displayDesktop = () => {
    return (
      <Toolbar sx={{ justifyContent: 'space-between' }}>
        {CustomSurgicalLogo}
        <ul style={styles.nav_menu}>
          <li>
            <Typography>
              <Link
                sx={styles.nav_link}
                href="https://customsurgical.co/"
                target="_blank"
                rel="noopener"
              >
                Home
              </Link>
            </Typography>
          </li>
          <li>
            <Typography>
              <Link
                sx={styles.nav_link}
                href="https://customsurgical.co/about/"
                target="_blank"
                rel="noopener"
              >
                About
              </Link>
            </Typography>
          </li>
          <li>
            <Typography>
              <Link
                sx={styles.nav_link}
                href="https://customsurgical.co/app/"
                target="_blank"
                rel="noopener"
              >
                App
              </Link>
            </Typography>
          </li>
          <li>
            <Typography>
              <Link
                sx={styles.nav_link}
                href="https://customsurgical.co/distributors/"
                target="_blank"
                rel="noopener"
              >
                Distributors
              </Link>
            </Typography>
          </li>
          <li>
            <Typography>
              <Link
                sx={styles.nav_link}
                href="https://blog.customsurgical.co/"
                target="_blank"
                rel="noopener"
              >
                Blog
              </Link>
            </Typography>
          </li>
        </ul>
        <CartButton />
      </Toolbar>
    );
  };

  const CustomSurgicalLogo = (
    <Link href="https://customsurgical.co/" target="_blank">
      <Typography
        variant="h6"
        component="h1"
        style={{ display: 'flex', alignItems: 'center' }}
      >
        <LogoSvg />
      </Typography>
    </Link>
  );

  return <AppBar sx={styles.headerAppBar}>{displayDesktop()}</AppBar>;
}

const styles = {
  headerAppBar: {
    background: '#202026',
    paddingRight: '32px',
    paddingLeft: '32px',
  },
  nav_menu: {
    display: 'flex',
    width: '40%',
    justifyContent: 'space-between',
    margin: '0',
    marginLeft: 'auto',
    marginRight: '4em',
    listStyleType: 'none',
  },

  nav_link: {
    color: '#fff',
    fontWeight: '500',
    textDecoration: 'none',
    ':hover': {
      color: 'rgb(255, 161, 0)',
      transition: '0.3s all',
      textDecoration: 'none',
    },
  },
};
